// /http://localhost:3000/retail-media/config/advertisers/create?name=Benove%20Eventos&seller_id=1234123&ad_types=banner,product&brands=coca,fanta&user_name=Breno%20Novelli&user_email=breno.novelli+teste_create_adv@newtail.com.br

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillInfoCircle, AiFillWallet } from 'react-icons/ai'
import {
  FiCheck,
  FiPlusCircle,
  FiSettings,
  FiSliders,
  FiTrash,
  FiX
} from 'react-icons/fi'
import { RiWallet3Line } from 'react-icons/ri'
import { toastr } from 'react-redux-toastr'
import { NavLink } from 'react-router-dom'

import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import AlertInfo from '~/components/AlertInfo'
import { Button, ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import Panel from '~/components/Panel'
import { Badge } from '~/components/UI/Badge'
import {
  FormGroup,
  ImageInput,
  Input,
  InputHidden,
  Label,
  Radio
} from '~/components/unform'
import { CheckboxList } from '~/components/unform/CheckboxList'
import { handleUploadProgressAxios, history } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useModal from '~/hooks/useModal'
import useQueryParams from '~/hooks/useQuery'
import { SuccessContainer } from '~/modules/retailMedia/components/CreateTransaction/styles'
import { adTypesOptions } from '~/modules/retailMedia/constants/adTypesOptions'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'
import {
  createAdvertiserService,
  uploadAdvertiserService
} from '~/modules/retailMedia/services/advertiser'

import AlertDuplicated from './components/AlertDuplicated'

const fakeResponse = {
  account_id: '01d843f0-a10a-11ef-80a5-05990b243fde',
  name: 'ADVERTISER COM LOGO',
  is_test: false,
  created_origin: 'PAC',
  disabled_at: null,
  deleted_at: null,
  active: true,
  id: 'fc4a1fa7-d50a-4596-b483-ed18f03e43b5',
  created_at: '2024-11-12T15:23:02.510Z',
  updated_at: '2024-11-12T15:23:02.510Z',
  account_logo:
    'https://cdn.newtail.com.br/retail_media/accounts/advertisers/assets/2024/11/12/f8962b416c7608b78ed64ee89248aad1.jpeg',
  account_theme: { primary_color: '#ffffff', header_color: '#ffffff' }
}

const CreateAdvertiserConfig: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [successResponse, setSuccessResponse] =
    useState<CreateAdvertiserResponse | null>()

  const { linkConfig } = useAppInfo()

  /**
   * <Handle success modal>
   */
  const [Modal, showModal, toggle, setIsShowing] = useModal()
  const handleCloseModal = () => {
    setIsShowing(false)
    setSuccessResponse(null)
  }
  /**
   * </Handle success modal>
   */

  /**
   * <Handle fot shortcut />
   */

  const isMac = /Mac/i.test(navigator.userAgent)
  /**
   * </ fot shortcut />
   */

  /**
   * <Handle params>
   */
  const searchParams = useQueryParams()
  const name = searchParams.get('name')
  const seller_id = searchParams.get('seller_id')
  const user_name = searchParams.get('user_name')
  const user_email = searchParams.get('user_email')
  const adTypesQuery = searchParams.get('ad_types')
  const brandsQuery = searchParams.get('brands')
  const adTypesSplited = useMemo(
    () => (adTypesQuery ? adTypesQuery.split(',') : []),
    [adTypesQuery]
  )

  const brandsSplited = useMemo(
    () =>
      (brandsQuery ? brandsQuery.split(',') : []).filter(
        (item, index, self) => self.indexOf(item) === index
      ),
    [brandsQuery]
  )

  /**
   * </Handle params>
   */

  const { t } = useTranslation()
  const formRef = useRef<FormHandles>()

  const {
    data: adTypes = [],
    error,
    isPending
  } = useAdTypes({ useRMID: false })

  const options = useMemo(
    () =>
      adTypesOptions
        .filter(item => adTypes.includes(item.value))
        .map(({ id, name, value, label, description }) => ({
          id,
          name,
          value,
          label,
          title: description
        })) as CheckboxOptionProps[],
    [adTypes]
  )
  /**
   * <Handle brands>
   */
  type Brand = string

  const [brands, setBrands] = useState<Brand[]>(
    brandsSplited?.length ? brandsSplited.map(item => `brand_${item}`) : []
  )

  type HandleChange = { data: OnChangeInputData; id: string }

  // const handleChangeBrands = ({ data: { value }, id }: HandleChange) => {
  //   const handleAction = (brands: { id: string; name: string }[]) => {
  //     const newData = brands?.map(item =>
  //       item.id !== id ? item : { ...item, name: value }
  //     )

  //     return newData
  //   }

  //   setBrands(handleAction)
  // }

  const handleDeleteBrands = (value: string) => {
    const handleAction = (brands: Brand[]) => {
      const newData = brands?.filter(item => item !== value)

      return newData
    }

    setBrands(handleAction)
  }

  const handleAddBrands = useCallback(() => {
    setBrands(prevState => {
      if (!prevState) return ['new_brand_01']

      const id = `brand_${Math.random().toString(36).slice(2, 11)}`
      return [...prevState, id]
    })
  }, [])

  const handleBrandInputKeyEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddBrands()
    }
  }

  /**
   * </Handle brands>
   */

  /**
   * <Handle change>
   */
  type AdvertiserFormData = {
    name: string
    seller_id?: string
  }

  const [formData, setFormData] = useState<AdvertiserFormData>({
    name,
    seller_id
  } as AdvertiserFormData)

  /**
   * <Handle user>
   */

  const [users, setUsers] = useState<AdvertiserUserState[]>([
    {
      id: 'mainUser',
      name: user_name,
      email: user_email,
      role: 'admin'
    }
  ])

  const handleNewUser = () => {
    const id = `user_${Math.random().toString(36).slice(2, 11)}`
    setUsers(users => [...users, { id }])
  }

  const handleDeleteUser = (userId: string) =>
    setUsers(users => users.filter(user => user.id !== userId))

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (isMac ? event.metaKey : event.ctrlKey) &&
        event.shiftKey &&
        event.key === 'u'
      ) {
        event.preventDefault()
        handleNewUser()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isMac])

  /**
   * </Handle user>
   */

  /**
   * <Handle upload image>
   */
  const [previewImage, setPreviewImage] = useState<string>()

  const handleUpload = useCallback(async event => {
    setLoadingUpload(true)

    const body = new FormData()

    try {
      const [file] = event.target.files
      body.append('file', file)

      const axiosOptions = handleUploadProgressAxios({
        onChange: setUploadProgress
      })

      /**
       * Função que será chamada ao final da promise disparada pelo Redux
       */
      const handleUploadSuccess = ({ data }) => {
        setPreviewImage(data.url)
        setLoadingUpload(false)
        setUploadProgress(0)
        formRef?.current?.setFieldValue(`logo`, data.url)
      }

      const response = await uploadAdvertiserService({ body, axiosOptions })

      handleUploadSuccess(response)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Erro', 'Houve um erro ao enviar a imagem.')
      setUploadProgress(0)
      setLoadingUpload(false)
    }
  }, [])

  const handlePreview = useCallback(
    e => {
      const file = e.target.files?.[0]

      if (!file) {
        setPreviewImage(null)
      }

      const [fileType] = file.type.split('/')

      const isFile = fileType === 'application'
      const isImage = fileType === 'image'

      if (!isFile && !isImage) {
        toastr.error('Erro', `Ainda não suportamos esse formato de arquivo.`)
      }

      const previewURL = URL.createObjectURL(file)

      handleUpload(e)

      setPreviewImage(previewURL)
    },
    [handleUpload]
  )

  const resetFileInput = useCallback(() => {
    if (formRef.current) {
      setPreviewImage(null)
      formRef.current.clearField('logoUpload')
      formRef.current.clearField('logo')
    }
  }, [formRef])

  /**
   * </Handle upload image>
   */

  const handleChange = useCallback((data: OnChangeInputData) => {
    if (!data.name) return
    setFormData(prevState => ({ ...prevState, [data.name]: data.value }))
  }, [])

  /**
   * </ Handle change>
   */

  const handleSubmit = useCallback(async (data: FormDataCreateAdvertiser) => {
    setLoading(true)
    const users = data.users.map(item => ({
      name: item.name,
      email: item.email,
      role: item.role as AdvertiserUser['role']
    })) as [AdvertiserUser, ...AdvertiserUser[]]

    delete data.logoUpload

    const DTO: CreateAdvertiserPayload = {
      ...data,
      name: data.name,
      seller_id: data.seller_id,
      logo: data.logo || null,
      users,
      ad_types: data.ad_types.all
        .filter(item => item.checked)
        .map(item => item.value) as CampaignAdType[],
      brands: data.brands ? Object.values(data.brands).filter(Boolean) : null
    }

    try {
      const response = await createAdvertiserService(DTO)

      toastr.success('Sucesso', 'Anunciante criado com sucesso.')

      setSuccessResponse(response.data)

      formRef.current?.reset()

      setPreviewImage(null)

      history.replace({
        pathname: window.location.pathname,
        search: ''
      })
    } catch (error) {
      console.error(error)
      toastr.error('Erro', 'Houve um erro ao criar o anunciante.')
    } finally {
      setLoading(false)
    }
  }, [])

  const initialData: Partial<FormInitialDataCreateAdvertiser> = useMemo(() => {
    const reduceAdTypes = adTypesSplited?.reduce(
      (acc, item) => ({ ...acc, [`type_${item}`]: true }),
      {}
    )

    const adTypes = adTypesSplited?.length
      ? reduceAdTypes
      : { type_product: true, type_banner: true }

    const reduceBrands = brandsSplited?.reduce(
      (acc, item) => ({ ...acc, [`brand_${item}`]: item }),
      {}
    )

    const brands = brandsSplited?.length ? reduceBrands : undefined

    return {
      name,
      seller_id,
      ad_types: adTypes,
      brands,
      users
    }
  }, [adTypesSplited, brandsSplited, name, seller_id, users])

  return (
    <>
      <PageTitle title={t('rm:config.advertisers.create.pageTitle')} />

      <HeaderPages title={t('rm:config.advertisers.create.pageTitle')} />

      <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
        <Panel title={t('rm:config.advertisers.create.form.mainInfo')}>
          <fieldset className="d-flex gap-3">
            <FormGroup>
              <Label
                htmlFor="name"
                isRequired
                text={t('rm:config.advertisers.create.form.name')}
              />

              <Input name="name" onChange={handleChange} required />
            </FormGroup>

            <FormGroup>
              <Label
                htmlFor="seller_id"
                text={t('rm:config.advertisers.create.form.sellerId')}
              />

              <Input name="seller_id" onChange={handleChange} />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="logo" text="Logo do anunciante" />

              <div className="previewLogo d-flex align-items-center gap-2">
                {previewImage && (
                  <>
                    <label htmlFor="logoUpload" className="mb-0">
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="rounded"
                        style={{ maxHeight: '32px' }}
                      />
                    </label>
                    <Button
                      template="transparentDanger"
                      iconLeft={<FiX />}
                      text="Remover imagem"
                      size="xsmall"
                      onClick={resetFileInput}
                      customWidth="auto"
                      className="p-0"
                    />
                  </>
                )}

                <ImageInput
                  name="logoUpload"
                  onChange={handlePreview}
                  accept="image/*"
                  className={previewImage ? 'd-none' : ''}
                />

                <InputHidden name="logo" />
              </div>
            </FormGroup>
          </fieldset>

          <AlertDuplicated field="advertiser_name" value={formData.name} />

          <AlertDuplicated field="seller_id" value={formData.seller_id} />

          <fieldset>
            <FormGroup className="border-top border-gray pt-3 mt-3">
              <Label text={t('rm:config.advertisers.create.form.adTypes')} />
              <CheckboxList
                name="ad_types"
                options={options}
                itemSize="small"
                grid={2}
              />
            </FormGroup>

            {linkConfig.includes('brands') && (
              <FormGroup className="border-top border-gray pt-3 mt-3">
                <Label
                  text={t('rm:formEditLink.form.brands.label')}
                  helperText={t('rm:formEditLink.form.brands.helperText')}
                />

                {brands?.length > 0 && (
                  <section className="d-flex flex-column gap-2 mb-2">
                    {brands?.map((value, index) => (
                      <div className="d-flex gap-2" key={value}>
                        <Input
                          name={`brands[${value}]`}
                          placeholder="Insira o nome da marca"
                          onKeyDown={handleBrandInputKeyEnterPress}
                          htmlIcon={
                            <span className="text-gray fs-6 pe-1">
                              #{index + 1}
                            </span>
                          }
                        />

                        <aside className="align-self-center">
                          <Button
                            text={<FiTrash />}
                            template="transparentDanger"
                            customWidth="auto"
                            title="Remover esta marca da lista"
                            onClick={() => handleDeleteBrands(value)}
                          />
                        </aside>
                      </div>
                    ))}
                  </section>
                )}

                <footer>
                  <Button
                    template="transparentPrimary"
                    text={
                      brands?.length
                        ? 'Adicionar outra marca'
                        : 'Adicionar marca'
                    }
                    iconLeft={<FiPlusCircle />}
                    onClick={handleAddBrands}
                    className="p-0"
                    size="xsmall"
                  />
                </footer>
              </FormGroup>
            )}
          </fieldset>
        </Panel>

        <Panel title={t('rm:config.advertisers.create.form.users')} description>
          <section className="d-flex flex-column gap-2 mb-2">
            {users.map(({ id }, index) => (
              <fieldset
                className={`d-flex gap-3 ${index ? 'border-top border-gray pt-2' : ''}`}
                key={`users[${id}]`}
              >
                <FormGroup className="flex-fill">
                  {!index && <Label isRequired text={t('users:name')} />}
                  <Input
                    htmlIcon={
                      <span className="text-gray fs-6 pe-1">#{index + 1}</span>
                    }
                    name={`users[${index}].name`}
                    placeholder="Insira o nome do usuário"
                    required
                    showWarningOnRequired
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup className="flex-fill">
                  {!index && <Label isRequired text={t('users:email')} />}
                  <Input
                    type="email"
                    placeholder="Insira o e-mail do usuário"
                    name={`users[${index}].email`}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup>
                  {!index && <Label isRequired text={t('users:permission')} />}
                  <Radio
                    name={`users[${index}].role`}
                    itemSize="tiny"
                    simple
                    initialValue="user"
                    options={[
                      {
                        value: 'admin',
                        label: 'Administrador',
                        id: `[${index}]admin`,
                        disabled: !index
                      },
                      {
                        value: 'user',
                        label: 'Usuário',
                        id: `[${index}]user`,
                        disabled: !index
                      },
                      {
                        value: 'read_only',
                        label: 'Somente leitura',
                        id: `[${index}]read_only`,
                        disabled: !index
                      }
                    ]}
                  />
                </FormGroup>

                <aside className="align-self-end mb-2 pb-1">
                  <Button
                    text={<FiTrash />}
                    template={!index ? 'transparent' : 'transparentDanger'}
                    customWidth="auto"
                    title="Remover estar usuário da lista"
                    disabled={!index}
                    onClick={() => handleDeleteUser(id)}
                  />
                </aside>
              </fieldset>
            ))}
          </section>

          <footer>
            <Button
              template="transparentPrimary"
              text="Adicionar outro usuário"
              iconLeft={<FiPlusCircle />}
              onClick={handleNewUser}
              className="p-0"
              iconRight={
                <Badge
                  size="small"
                  text={`${isMac ? '⌘' : 'Ctrl'} + Shift + U`}
                />
              }
              size="xsmall"
            />
          </footer>
        </Panel>

        <footer className="d-flex justify-content-end mt-3">
          <Button
            type="submit"
            text="Criar anunciante"
            textLoading="Criando anunciante..."
            loading={loading}
          />
        </footer>
      </Form>

      <Modal
        isOpen={!!successResponse}
        onClose={handleCloseModal}
        maxWidth="auto"
      >
        <SuccessContainer>
          <header>
            <span className="icon">
              <FiCheck />
            </span>

            <h2 className="title">{t('rm:success')}</h2>

            <p className="description">
              O anunciante <strong>{successResponse?.name}</strong> foi criado
              com sucesso.
            </p>
          </header>

          <AlertInfo
            className="w-100"
            template="info"
            text="O anunciante receberá um e-mail para ativar a conta e acessar a plataforma."
          />

          <hr className="w-100" />

          <section className="text-center">
            <h4>O que você deseja fazer agora?</h4>

            <nav className="d-flex flex-column justify-content-center gap-2">
              <ButtonNavLink
                to={`/retail-media/wallet/${successResponse?.checking_account_id}/movimentations`}
                template="light"
                text="Adicionar saldo na carteira"
                iconLeft={<RiWallet3Line />}
                size="small"
              />

              <ButtonNavLink
                to={`/retail-media/config/advertisers?rmid=${successResponse?.id}`}
                template="light"
                text="Editar anunciante"
                size="small"
                iconLeft={<FiSliders />}
              />

              <Button
                text="Criar outro anunciante"
                template="primary"
                onClick={handleCloseModal}
                size="small"
                iconLeft={<FiPlusCircle />}
              />
            </nav>
          </section>
        </SuccessContainer>
      </Modal>
    </>
  )
}

export default CreateAdvertiserConfig
