import { useQuery } from '@tanstack/react-query'

import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'
import useQueryParams from '~/hooks/useQuery'
import {
  listAdvertisersService,
  listAdvertisersServiceKey
} from '~/modules/retailMedia/services/advertiser-publisher'

export const useAdvertiserPublisherLinks = (
  { useAdvertiserId } = { useAdvertiserId: false }
) => {
  // Por enquanto só pega do query params. Se preciso, podemos receber alternativa por props do useAdTypes
  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const advertiser_name = params.get('advertiser_name')
  const seller_id = params.get('seller_id')
  const tag_id = params.get('tag_id') as PublisherTagId

  const advertiserId = params.get('rmid') || params.get('edit_tags_aid')

  const queryKey = useAdvertiserId
    ? [listAdvertisersServiceKey, 'p-1', 'q-1', advertiserId]
    : [
        listAdvertisersServiceKey,
        page,
        quantity,
        advertiser_name,
        seller_id,
        tag_id
      ]

  const cachedData = useGetFetchQuery(
    queryKey
  ) as ListAdvertisersServiceResponse

  const {
    isPending,
    data: response,
    error
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const payloadSingle = {
        advertiser_id: advertiserId
      }
      const payloadList = {
        page,
        quantity,
        advertiser_name,
        seller_id,
        tag_id
      }

      const payload = useAdvertiserId ? payloadSingle : payloadList

      const response = await listAdvertisersService(payload)

      return response.data
    }
  })

  const data = cachedData || response

  return {
    isPending,
    data: {
      data: data?.data,
      total: data?.total,
      pages: data?.pages,
      currentPage: data?.currentPage
    },
    error,
    queryKey
  }
}
